var Singleton = (function () {
    var 
        instance,
        self = this
    ;

    self.setDocumentEvents = function () {
        if (typeof document != 'object') {
            return;
        }

        jQuery(document).on('ajax-submitting', function () {
            if (jQuery('#submitting').length > 0) {
                jQuery('#submitting').show();
            }
        });

        jQuery(document).on('ajax-submitting-done', function() {
            if (jQuery('#submitting').length > 0) {
                jQuery('#submitting').hide();
            }
        });
    }

    self.setValidatorDefaults = function () {
        jQuery.validator.addMethod('phone', function (val, elem) {
            return /[0-9\s]+/.test(val);
        }, 'Please enter a valid telephone number.' );

        jQuery.validator.setDefaults({
            rules: {
                name: {
                    required: true,
                    minlength: 2
                },
                email: {
                    required: true,
                    email: true
                },
                telephone: {
                    required: true,
                    phone: true,
                    minlength: 5
                },
                message: {
                    required: true,
                    minlength: 2
                },
            },
            errorClass: 'input-error',
            validClass: 'valid',
            errorPlacement: function (error, element) {
                element.after(error);
            },
            success: function (error) {
                if (error) {
                    error.remove();
                }
            },
          focusInvalid: false,
          submitHandler: function (form) {
            var 
                form = jQuery(form),
                formData = jQuery(form).serializeArray()
            ;

                form.trigger('ajax-submitting');
                jQuery('.contact-form .form-group, .contact-form .form-check').hide();

                jQuery.ajax({
                    url: ajaxUrl,
                    type: 'POST',
                    data: formData,
                    dataType: 'json',
                    success: function (response) {
                        if (response.success == true) {
                            if (jQuery('.error-container').length > 0 && jQuery('.error-container').is(':visible')) {
                                jQuery('.error-container').hide();
                                jQuery('.error-container ul').empty();
                            }
                            form.trigger('ajax-submitting-done');
                            jQuery('#thank-you').show();
                            form.trigger('ajax-submitting-done');
                        } else {
                            form.trigger('ajax-submitting-done');
                            form.find('.form-group, .form-check').show();
                            if (typeof response.data.errors !== 'undefined') {
                                var errorContainer = form.find('#errors'),
                                    errorList = errorContainer.find('ul');

                                if (errorContainer.length < 1 || errorList.length < 1) {
                                    return;
                                }

                                for (var error in response.data.errors) {
                                    errorList.append('<li>' + response.data.errors[error] + '</li>');
                                }
                            }
                        }
                    },
                    error: function(response) {
                        if( response.success !== true) {
                            form.trigger('ajax-submitting-done');
                            form.find('.form-group, .form-check').show();
                            if (typeof response.data.errors !== 'undefined') {
                                var errorContainer = form.find('#errors'),
                                    errorList = errorContainer.find('ul');

                                if (errorContainer.length < 1 || errorList.length < 1) {
                                    return;
                                }

                                for (var error in response.data.errors) {
                                    errorList.append('<li>' + response.data.errors[error] + '</li>');
                                }
                            }
                        }
                    }
                });

                return false;
            }
        });
    };

    self.setupPartnerLogos = function (carousel) {
        jQuery(carousel).on('init', function (event, slick) {
            var slideContainer = jQuery(slick.$slider);

            slideContainer.find('.slick-slide').addClass('d-flex justify-content-center');
        });

        jQuery(carousel).slick({
            slidesToShow: 8,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 1500,
            infinite: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }

    self.setupFeaturedProductsScroller = function (carousel) {
        jQuery(carousel).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true, 
            variableWidth: false,
            rows: 0,
            prevArrow: '<a href="#" class="prev-arrow carousel-control-prev"><span class="prev-icon"></span></a>',
            nextArrow: '<a href="#" class="next-arrow carousel-control-next"><span class="next-icon"></span></a>',
        });
    }

    self.setupHeaderScroll = function (header) {
        var header = jQuery(header);

        jQuery(window).on('scroll', function () {
            var scroll = jQuery(this).scrollTop();

            if (scroll > 105) { 
                if (header.hasClass('has-scrolled')) {
                    return;
                }

                header.addClass('has-scrolled');
            } else {
                header.removeClass('has-scrolled');
            }
        });
    }

    self.setupContactMap = function (mapElem) {        
        var 
            center = {
                lat: coordinates[0],
                lng: coordinates[1]
            },
            map = new google.maps.Map(jQuery(mapElem).get(0), {
            center: center,
            zoom: 11
        });

        var marker = new google.maps.Marker({
            position: center,
            map: map,
            icon: templateUrl + '/assets/img/icons/sherpa-blue-marker.png' 
        });
    }

    self.setupImagePopup = function (popup) {
        jQuery(popup).on({
            'click': function (event) {
                var popup = jQuery(event.currentTarget);

                if (!jQuery(event.target).is(popup)) {
                    return;
                }

                popup.trigger('hide.bs.modal');
            },

            'show.bs.modal': function (event) {
                var
                    popupHeader = jQuery(event.currentTarget).find('.modal-header'),
                    popupBody = jQuery(event.currentTarget).find('.modal-body'),
                    popupLink = jQuery(event.relatedTarget)
                ;

                if (popupBody.length < 1 || popupLink.length < 1 || !popupLink.data('image')) {
                    return;
                }

                if (popupHeader.length > 0 && popupLink.data('image-title')) {
                    popupHeader.prepend('<h3>' + popupLink.data('image-title') + '</h3>');
                }

                popupBody.append('<img class="img-fluid min-height-15-rem" src="' + popupLink.data('image') + '" />');
            },

            'hide.bs.modal': function (event) {
                var
                    popupHeader = jQuery(event.currentTarget).find('.modal-header'),
                    popupBody = jQuery(event.currentTarget).find('.modal-body')
                ;

                if (popupHeader.length > 0 && popupHeader.find('h3').length > 0) {
                    popupHeader.find('h3').remove();
                }

                if (popupBody.find('img').length > 1 || !popupBody.is(':empty')) {
                    popupBody.find('img').remove();
                }
            }
        });
    }

    self.setupGalleryLightbox = function (modal) {


        jQuery(document).on('click', '.gallery-item', function(event){
            var 
                self = jQuery(this),
                image = self.data('gallery-img'),
                text = self.data('img-text'),
                lightbox_orientation = self.data('lightbox-orientation'),
                modal_size = self.data('modal-size'),
                modal_body_padding_bottom = self.data('modal-body-padding-bottom')
            ;
            
            // remove previously set modal size classes and dynamically add new 
            modal.find('.modal-dialog').removeClass('modal-md modal-lg portrait landscape')
                .addClass(lightbox_orientation + ' modal-' + modal_size);
                
            modal.find('.modal-body').css({'padding-bottom': modal_body_padding_bottom});
            modal.find('.modal-body').css({'background-image': 'url(' +  image +  ')'});
            modal.find('.modal-footer').html('<p>' + text + '</p>');
        });
    }

    self.loadPosts = function(post_type, category, value, posts_per_page, page) {

        var 
            container = jQuery('.ajax-load-posts'),    
            action = 'load-posts',
            page = page || 1,
            data = {}
        ;

        if (post_type) {
            data.post_type = post_type;
        }

        if (category) {
            data.category = category;
        }

        if (value) {
            data.value = value;
        }

        if (posts_per_page) {
            data.posts_per_page = posts_per_page;
        }
        
        data.page = page;
        data.action = action;

        
        jQuery.post(ajaxUrl, data, function(response, status, xhr) {
            container.append(response.data.html);

            if (response.data.hasOwnProperty('more') && response.data.more) {
                container.addClass('ajax-load-more');
                self.ajaxPostsLoadMore(container, response.data.total_pages);
            } else {
                container.removeClass('ajax-load-more');
            
            }
        });
    }
    
    self.setupAjaxLoadPosts = function(element) {
        var 
            post_type = element.data('post-type'),
            posts_per_page = element.data('posts-per-page'),
            category = false,
            value = false
        ;

        self.loadPosts(post_type, category, value, posts_per_page);

    }

    self.setupAjaxFilterPosts = function (el) {
        el.on('click', function(event) {
            var 
                $this = jQuery(this),
                postsContainer = jQuery('.ajax-load-posts'),
                post_type = $this.data('post-type'),
                category = $this.data('category') || '',
                value = $this.data('value') || '',
                posts_per_page = $this.data('posts-per-page') || ''
                postsContainer.data('page', 1);
            ;
            $this.addClass('active').siblings().removeClass('active');
            postsContainer.empty();
            
            self.loadPosts(post_type, category, value, posts_per_page, 1);
        });
    }

    self.ajaxPostsLoadMore = function(el, total_pages) {

        var 
            postsContainer = el,
            post_type = postsContainer.data('post-type'),
            filter = jQuery('.ajax-filter-posts.active'),
            category = filter.data('category') || '',
            value = filter.data('value') || '',
            posts_per_page = postsContainer.data('posts-per-page') || '',
            page = parseInt(postsContainer.data('page') + 1)
        ;
            
            
        jQuery(window).on('scroll', function(){
            

            if ( jQuery(window).scrollTop() >= postsContainer.offset().top 
                + postsContainer.outerHeight() - window.innerHeight) {

                if (page <= total_pages) {
                    self.loadPosts(post_type, category, value, posts_per_page, page);
                    page++;
                    
                    postsContainer.data('page', page);
                }
            }
        
        });
}

    self.setActiveNavLink = function (navLink) {
        jQuery(navLink).filter(function () {
            if (this.href != window.location.href) {
                return false;
            }

            return this.href.indexOf(window.location.pathname) > -1;
        }).addClass('active');
    }

    self.elementExists = function (sel) {
        return jQuery(sel).length > 0;
    };

    self.bindEvents = function () {
        self.setDocumentEvents();

        if (self.elementExists('.contact-form')) {
            self.setValidatorDefaults();

            jQuery('.contact-form').each(function () {
                jQuery(this).validate();
            });
        }

        if (self.elementExists('.menu-item > a')) {
            self.setActiveNavLink('.menu-item > a');
        }

        if (self.elementExists('.partner-logos')) {
            self.setupPartnerLogos(jQuery('.partner-logos'));
        }

        if (self.elementExists('#featured-products')) {
            self.setupFeaturedProductsScroller(jQuery('#featured-products'));
        }

        if (self.elementExists('header')) {
            self.setupHeaderScroll(jQuery('header'));
        }

        if (self.elementExists('.contact-map')) {
            self.setupContactMap(jQuery('.contact-map'));
        }

        if (self.elementExists('.modal')) {
            self.setupImagePopup(jQuery('.modal'));
        }

        if (self.elementExists('.ajax-load-posts')) {
            self.setupAjaxLoadPosts(jQuery('.ajax-load-posts'));
        }

        if (self.elementExists('.ajax-filter-posts')) {
            self.setupAjaxFilterPosts(jQuery('.ajax-filter-posts'));
        }

        if (self.elementExists('#gallery-modal')) {
            self.setupGalleryLightbox(jQuery('#gallery-modal'));
        }
    };
    
    function init() {
        self.bindEvents();
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }

            return instance;
        }
    };
})();

jQuery(document).ready(function () {
    var singleton = Singleton.getInstance();
});
